<template>
	 <div class="footer" id="app">
		 <div class="food">
			 <div class="friendly_link">
			 	<label>友情链接：</label>
			 	 <a href="https://gisp.scszsj.com/">四川游戏研发出版运营综合服务平台</a>|
			 	 <a href="https://acgbanquan.scszsj.com/">四川动漫游戏版权平台</a>
<!--			 	 <a href="https://book.cangqiongkanshu.com/">苍穹文学</a>|-->
<!--			 	 <a href="https://yx.scszcb.com/">游戏出版平台</a>|-->
<!--			 	 <a href="http://www.xbmusic.net/">西部音乐基地</a>-->
			 </div>
			 <div class="food_left" >
				<div class="phone">
					<label>电话：</label>
					<p>028-85058232</p>
				</div>
				<div class="phone addr">
					<label>地址：</label>
					<p>四川省成都市武侯区天府长岛9栋</p>
				</div>
			 </div>
			 <div class="middle">
				 <p>邮政编码：610000</p>
				 <p  @click="centerDialogVisible = true" style="display: none">营业执照</p>
				 <p  @click="PublicationLicence = true"> 平台用户协议条款</p>
				 <p  @click="institution = true"> 互联网文化内容审核管理制度</p>
			 </div>
			 <div class="foot_right">
				 <img src="../assets/img/code.png" alt="">
         <p>四川游戏创新发展中心微信公众号</p>
			 </div>
			 <div class="clear"></div>
		 </div>
		 <p class="record"><el-link href="https://beian.miit.gov.cn">蜀ICP备2023022502号-1</el-link></p>

     <el-dialog
         v-model="centerDialogVisible"
         width="80%"
         align-center
     >
       <template #footer>
         <img class="Business_license" src="../assets/img/yyzz.png" alt="">
       </template>
     </el-dialog>

     <el-dialog
         v-model="PublicationLicence"
         width="80%"
         align-center
         class="PublicationLicence"
     >
<!--       <template #footer>-->
         <h2 style="text-align:center">
           <span style=";font-family:方正小标宋_GBK;font-size:29px">平台用户协议条款</span>
         </h2>
         <p style="text-indent:32px">
           <span style=";font-family:宋体;font-size:16px">&nbsp;</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（一）</span><span style=";font-family:仿宋_GB2312;font-size:21px">各用户</span><span style=";font-family:仿宋_GB2312;font-size:21px">同意按照本协议的规定及其不时发布的操作规则提供基于互联网以及移动网的相关服务（以下称网络服务），为获得网络服务，服务使用人（以下称用户）应当同意本协议的全部条款并按照页面上的提示完成全部的注册程序。用户在进行注册程序过程中点击同意按钮即表示用户完全接受本协议项下的全部条款。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（二）用户注册成功后，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站将给予每个用户一个用户账号及相应的密码，该用户账号和密码由用户负责保管；用户应当对以其用户账号进行的所有活动和事件负法律责任。</span>
         </p>
         <p style="text-indent: 43px; ">
           <strong><span style=";font-family:黑体;font-size:21px">二、服务内容</span></strong>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（一）</span><span style=";font-family:仿宋_GB2312;font-size:21px">相关</span><span style=";font-family:仿宋_GB2312;font-size:21px">服务的具体内容由</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px"><span style="font-family:仿宋_GB2312">网站根据实际情况提供，例如搜索、手机图片铃声、交友、论坛（</span><span style="font-family:仿宋_GB2312">bb）、聊天室、电子邮件、发表新闻评论等。</span></span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（二）</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站提供的部分网络服务（例如手机图片铃声、电子邮件等）为收费的网络服务，用户使用收费网络服务需要向网站支付一定的费用。对于收费的网络服务，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站会在用户使用之前给予用户明确的提示，只有用户根据提示确认其愿意支付相关费用，用户才能使用该等收费网络服务。如用户拒绝支付相关费用，则网站有权不向用户提供该等收费网络服务。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（三）</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站仅提供相关的网络服务，除此之外与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费）均应由用户自行负担。</span>
         </p>
         <p style="text-indent: 43px; ">
           <strong><span style=";font-family:黑体;font-size:21px">三</span><span style=";font-family:黑体;font-size:21px">、</span><span style=";font-family:黑体;font-size:21px">服务变更、中断或终止</span></strong>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（一）鉴于网络服务的特殊性，用户同意</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站有权随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。如变更、中断或终止的网络服务属于网络服务，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站无需通知用户，也无需对任何用户或任何第三方承担任何责任；如变更、中断或终止的网络服务属于收费网络服务，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站应当在变更、中断或终止之前事先通知用户，并应向受影响的用户提供等值的替代性的收费网络服务，如用户不愿意接受替代性的收费网络服务，就该用户已经向</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站支付的服务费，网站应当按照该用户实际使用相应收费网络服务的情况扣除相应服务费之后将剩余的服务费退还给该用户。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（二）用户理解，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站需要定期或不定期地对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，如因此类情况而造成收费网络服务在合理时间内的中断，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站无需为此承担任何责任，但</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站应尽可能事先进行通告。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px"><span style="font-family:仿宋_GB2312">（三）如发生下列任何一种情形，</span><span style="font-family:仿宋_GB2312">_</span></span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站有权随时中断或终止向用户提供本协议项下的网络服务（包括收费网络服务）而无需对用户或任何第三方承担任何责任：</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">1、用户提供的个人资料不真实；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">2、用户违反本协议中规定的使用规则；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">3、用户在使用收费网络服务时未按规定向网站支付相应的服务费。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（四）如用户注册的网络服务的账号在任何连续</span><span style=";font-family:仿宋_GB2312;font-size:21px">360</span><span style=";font-family:仿宋_GB2312;font-size:21px">日内未实际使用，或者用户注册的收费网络服务的账号在其订购的收费网络服务的服务期满之后连续</span><span style=";font-family:仿宋_GB2312;font-size:21px">720</span><span style=";font-family:仿宋_GB2312;font-size:21px">日内未实际使用，则</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">有权删除该账号并停止为该用户提供相关的网络服务。</span>
         </p>
         <p style="text-indent: 43px; ">
           <strong><span style=";font-family:黑体;font-size:21px">四、使用规则</span></strong>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（一）用户在申请使用</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">站网络服务时，必须向</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站提供准确的个人资料，如个人资料有任何变动，必须及时更新。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（二）用户不应将其账号、密码转让或出借予他人使用。如用户发现其账号遭他人非法使用，应立即通知</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站。因黑客行为或用户的保管疏忽导致账号、密码遭他人非法使用，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站不承担任何责任。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（三）对于用户通过</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px"><span style="font-family:仿宋_GB2312">网络服务（包括但不限于论坛、</span><span style="font-family:仿宋_GB2312">bb、新闻评论、个人家园）上传到</span></span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站上可公开获取区域的任何内容，用户同意</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站在全世界范围内具有的、永久性的、不可撤销的、非独家的和完全再许可的权利和许可，以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示此等内容（整体或部分），和或将此等内容编入当前已知的或以后开发的其他任何形式的作品、媒体或技术中。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（四）用户在使用</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网络服务过程中，必须遵循以下原则：</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">1、遵守中国有关的法律和法规；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">2、遵守所有与网络服务有关的网络协议、规定和程序；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">3、不得为任何非法目的而使用网络服务系统；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">4、不得利用</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网络服务系统进行任何可能对互联网或移动网正常运转造成不利影响的行为；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">5、不得利用</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站提供的网络服务上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">6、不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">7、不得利用</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网络服务系统进行任何不利于</span><span style=";font-family:仿宋_GB2312;font-size:21px">数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">的行为；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">8、</span><span style=";font-family:仿宋_GB2312;font-size:21px">如发现任何非法使用用户账号或账号出现安全漏洞的情况，应立即通告</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（五）如用户在使用网络服务时违反任何上述规定，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站或其授权的人有权要求用户改正或直接采取一切必要的措施（包括但不限于更改或删除用户张贴的内容等、暂停或终止用户使用网络服务的权利）以减轻用户不当行为造成的影响。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（六）</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站针对某些特定的网络服务的使用通过各种方式（包括但不限于网页公告、电子邮件、短信提醒等）作出的任何声明、通知、警示等内容视为本协议的一部分，用户如使用该等网络服务，视为用户同意该等声明、通知、警示的内容。</span>
         </p>
         <p style="text-indent: 43px; ">
           <strong><span style=";font-family:黑体;font-size:21px">五、知识产权</span></strong>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（一）</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站提供的网络服务中包含的任何文本、图片、图形、音频和或视频资料均受版权、商标和或其它财产所有权法律的保护，未经相关权利人同意，上述资料均不得在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者被用于其他任何商业目的。所有这些资料或资料的任何部分仅可作为私人和非商业用途而保存在某台计算机内。</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站不就由上述资料产生或在传送或递交全部或部分上述资料过程中产生的延误、不准确、错误和遗漏或从中产生或由此产生的任何损害赔偿，以任何形式，向用户或任何第三方负责。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（二）</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px"><span style="font-family:仿宋_GB2312">网站为提供网络服务而使用的任何软件（包括但不限于软件中所含的任何图象、照片、动画、录像、录音、音乐、文字和附加程序、随附的帮助材料）的一切权利均属于该软件的著作权人，未经该软件的著作权人许可，用户不得对该软件进行反向工程（</span><span style="font-family:仿宋_GB2312">eee-egee）、反向编译（dece）或反汇编（dxebe）</span></span><span style=";font-family:仿宋_GB2312;font-size:21px">。</span>
         </p>
         <p style="text-indent: 43px; ">
           <strong><span style=";font-family:黑体;font-size:21px">六、隐私保护</span></strong>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（一）保护用户隐私是</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站的一项基本政策，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站保证不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在网站的非公开内容，但下列情况除外：</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">1、事先获得用户的明确授权；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">2、根据有关的法律法规要求；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">3、按照相关政府主管部门的要求；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">4、为维护社会公众的利益；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">5、为维护网站的合法权益。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（二）</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站同等的保护用户隐私的责任，则网站有权将用户的注册资料等提供给该第三方。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（三）在不透露单个用户隐私资料的前提下，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</span>
         </p>
         <p style="text-indent: 43px; ">
           <strong><span style=";font-family:黑体;font-size:21px">七、免责声明</span></strong>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（一）用户明确同意其使用</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网络服务所存在的风险将完全由其自己承担；因其使用</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网络服务而产生的一切后果也由其自己承担，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">对用户不承担任何责任。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（二）</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（三）</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站实际控制的任何网页上的内容，网站不承担任何责任。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（四）对于因不可抗力或不能控制的原因造成的网络服务中断或其它缺陷，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（五）用户同意，对于</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站向用户提供的下列产品或者服务的质量缺陷本身及其引发的任何损失，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站无需承担任何责任：</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">1、向用户提供的各项网络服务；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">2、向用户赠送的任何产品或者服务；</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">3、向收费网络服务用户附赠的各种产品或者服务。</span>
         </p>
         <p style="text-indent: 43px; ">
           <strong><span style=";font-family:黑体;font-size:21px">八、违约赔偿</span></strong>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（一）如因</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站违反有关法律、法规或本协议项下的任何条款而给用户造成损失，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站同意承担由此造成的损害赔偿责任。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（二）用户同意保障和维护</span><span style=";font-family:仿宋_GB2312;font-size:21px">，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站及其他用户的利益，如因用户违反有关法律、法规或本协议项下的任何条款而给</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</span>
         </p>
         <p style="text-indent: 43px; ">
           <strong><span style=";font-family:黑体;font-size:21px">九、协议修改</span></strong>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（一）</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站有权随时修改本协议的任何条款，一旦本协议的内容发生变动，</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站将会通过适当方式向用户提示修改内容。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（二）如果不同意</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站对本协议相关条款所做的修改，用户有权停止使用网络服务。如果用户继续使用网络服务，则视为用户接受</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站对本协议相关条款所做的修改。</span>
         </p>
         <p style="text-indent: 43px; ">
           <strong><span style=";font-family:黑体;font-size:21px">十、通知送达</span></strong>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（一）本协议项下</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站对于用户所有的通知均可通过网页公告、电子邮件、手机短信或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（二）用户对于</span><span style=";font-family:仿宋_GB2312;font-size:21px">四川数字世界</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站的通知应当通过网站对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。</span>
         </p>
         <p style="text-indent: 43px; ">
           <strong><span style=";font-family:黑体;font-size:21px">十一、法律管辖</span></strong>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（一）本协议的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（二）如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向</span><span style=";font-family:仿宋_GB2312;font-size:21px">网站归属地当地</span><span style=";font-family:仿宋_GB2312;font-size:21px">人民法院提起诉讼。</span>
         </p>
         <p style="text-indent: 43px;">
           <span style=";font-family:黑体;font-size:21px">十二、其他规定</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（一）本协议构成双方对本协议之约定事项及其他有关事宜的完整协议，除本协议规定的之外，未赋予本协议各方其他权利。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（二）如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</span>
         </p>
         <p style="text-indent:43px">
           <span style=";font-family:仿宋_GB2312;font-size:21px">（三）本协议中的标题仅为方便而设，在解释本协议时应被忽略。</span>
         </p>
         <p>
           <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
         </p>
         <p>
           <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
         </p>
         <p>
           <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
         </p>
         <p>
           <span style=";font-family:Calibri;font-size:14px">&nbsp;</span>
         </p>
         <p>
           <br/>
         </p>
<!--       </template>-->
     </el-dialog>


     <el-dialog v-model="institution"     class="institution"  center>
       <h2 style="text-align: center;"><strong>平台管理制度</strong></h2>
       <p style="text-align: center;"><strong>第一章&nbsp;&nbsp;总则</strong></p>
       <p>第一条&nbsp;为进一步四川数字世界（以下称数字世界）网站的建设、运行和管理，促进网站健康发展，依据国家有关法律法规，结合数字世界实际，特制定本办法。</p>
       <p>第二条&nbsp;本办法所称网站是指数字世界及有关部门和直属单位建立的通过国际互联网面向社会公众的网站及其子网站（以下简称&rdquo;网站&rdquo;）。</p>
       <p>第三条&nbsp;数字世界网站是数字世界及其部门和直属单位在互联网上发布信息、提供公共服务和接受公众监督的重要平台和窗口，是数字世界信息化建设的重要组成部分。</p>
       <p>第四条&nbsp;数字世界门户网站的建设、运行和管理实行&rdquo;统一领导，统筹规划、分工协作、各负其责&rdquo;的原则。各部门、各中心实行&ldquo;谁建设、谁负责&rdquo;的原则。</p>
       <p>第五条&nbsp;本办法适用于数字世界各部各中心。</p>
       <p style="text-align: center;"><strong>第二章&nbsp;&nbsp;组织管理</strong></p>
       <p>第六条&nbsp;技术部是数字世界门户网站的归口管理部门。负责全所网站建设、运行和管理的指导、协调和监督，审批数字世界门户网站有关链接。承办数字世界门户网站的建设和运行维护，负责对全所网站运行状况实施监测，为有关部门、单位提供网站的网络环境和技术支持。负责数字世界门户网站的日常运行管理和网站内容的采编及其发布。</p>
       <p>第七条&nbsp;各中心网站应明确一名领导分工负责，由本中心技术部负责管理。各中心网站由本中心确定一名负责人负责管理。各网站管理中心要有一定的条件和技术实力，并要做到专（兼）职人员定岗、定责。</p>
       <p style="text-align: center;"><strong>第三章&nbsp;&nbsp;内容管理</strong></p>
       <p>第八条&nbsp;保密委员会负责确定数字世界门户网站栏目的设立和重要上网内容的审核，并对各栏目信息更新情况进行跟踪监督，提出改进意见，承办单位按照要求具体组织实施。各中心或相关职能部门负责确定所属网站栏目的设立和上网的内容，及时做好网站信息的更新。有关部门主管负责确定所属网站栏目的设立和上网的内容，及时做好网站信息的更新。</p>
       <p>第九条&nbsp;各网站栏目设置可根据业务具体确定，网站一般应包括以下栏目：</p>
       <p>（一）所级：关于我们、主要业务、解决方案、成功案例、新闻动态、技术文献、业务中心等；</p>
       <p>（二）中心级：主要业务、解决方案、成功案例等；</p>
       <p>第十条&nbsp;除法定保密事项外，数字世界门户网站及直属单位网站应公开下列信息：</p>
       <p>（一）本单位服务事项的项目名称，办事地点及联系方式；</p>
       <p>（二）本单位的机构设置；</p>
       <p>（三）本单位需要向社会发布的公告、公示、通知以及新闻发布内容等；</p>
       <p>（四）其他应予公开的信息。</p>
       <p>第十一条 各网站应建立上网信息更新维护责任制。信息来源可通过自编信息、网上抓取、信息报送、网站链接等方式获得。建立信息上网审核和发布制度，未经审核的信息不得上网发布。网站应做到信息及时更新，防止重大信息缺失、滞后。</p>
       <p>第十二条&nbsp;网上信息和相应的服务，遵循&rdquo;谁发布，谁负责；谁承诺，谁办理&rdquo;的原则。网站发布、转载其他媒体新闻应当依据国家有关规定执行。</p>
       <p>第十三条&nbsp;任何单位和个人不得利用网站散布含有危害国家安全和社会稳定的信息，不得泄露国家秘密和商业秘密，不得宣扬暴力、色情等内容。</p>
       <p>第十四条&nbsp;网站不得与非法网站建立超级链接，也不得从事与网站所属部门、中心身份不符的活动。</p>
       <p>第十五条&nbsp;网站的基本链接关系包括：</p>
       <p>（一）数字世界门户网站在网站首页的醒目位置，列出所中心网站、行业网站的基本链接区，达到从数字世界门户网站可方便访问各链接网站的要求。</p>
       <p>（二）各中心网站在链接区的重要位置以文字或图标形式建立数字世界门户网站链接，保持上通下达。</p>
       <p>第十六条&nbsp;数字世界门户网站应在主页的显著位置设置数字世界徽志。</p>
       <p>第十七&nbsp;条各网站网页设计应当做到庄重、典雅、大方和美观，体现数字世界形象，发挥网上名片作用。</p>
       <p style="text-align: center;"><strong>第四章&nbsp;网站域名规范和运行维护</strong></p>
       <p>第十八条&nbsp;根据《中国互联网络域名注册暂行管理办法》相关规定，结合数字世界实际，数字世界网站的域名管理遵循以下规范：</p>
       <p>（一）数字世界门户网站域名为www.scszsj.com，中文域名为四川数字世界文化科技有限公司。</p>
       <p>（二）数字世界在国际互联网上不再申请独立域名。</p>
       <p>（三）各中心可在国际互联网上申请独立域名；独立域名获批15日内，其主办单位须将注册域名报技术部备案，并抄所办公室。</p>
       <p>（四）各中心在数字世界门户网站采用虚拟主机或主机托管方式建设二级网站，其域名（网址）由数字世界统一分配和解析。</p>
       <p>第十九条&nbsp;网站的运行维护应当遵守下列要求：</p>
       <p>（一）各网站承办单位必须保障网站的正常运行和提供服务，保证网站在工作日和节假日24小时开通，方便公众访问。如有特殊情况不能向社会提供服务，应及时采取措施向社会发布公告。</p>
       <p>（二）在数字世界门户网站采用虚拟主机或主机托管方式的网站安全及网络管理由数字世界负责，信息维护由委托方负责。</p>
       <p>第二十条&nbsp;数字世界技术部应定期对各中心网站运行状况进行监测，定期发布监测结果。如发现问题，立即通知有关中心采取措施加以解决。</p>
      </el-dialog>
	 </div>


</template>

<script setup>
import { ref } from 'vue'
import {FormInstance} from "element-plus";
	import Footer from '@/components/Footer'
const centerDialogVisible = ref(false)
const PublicationLicence = ref(false)
const institution = ref(false)
// 	export default {
// 	  components: {
// 		Footer
// 	  }
// }
</script>

<style>
	 .footer{
		 width: 100%;
		 height: auto;
     padding-bottom: 20px;
		 color: #fff;
		 font-size: 14px;
		 overflow: hidden;
		background-color: #424140;
	 }
	 .food{
		 width: 1200px;
		 margin: 60px auto 0 ;

		 padding-bottom: 40px;
		 border-bottom: 1px solid  #999999;
	 }
	 .food_left{
		 width: 590px;
		 float: left;
	 }
	 .friendly_link{
		 width: 100%;
		color: #f5f5f5;
	 }
	 .friendly_link a{
		 margin: 0 10px;
		 /* border-radius: ; */
	 }
	 .phone{
		 margin-top: 30px;
	 }
	 .phone label{
		 color: #d6d6d6;
		 margin-bottom: 10px;
	 }
	 .phone p{
		margin-top: 10px;
	 }
	 .addr{
		 margin-top: 55px;
	 }
	 .middle{
		 width: 365px;
		  float: left;
		 	color: #b4b4b6;
	 }
	 .middle p{
		 margin-top: 26px;
		color: #fffffe;
     cursor: pointer;
	 }

	 .foot_right{
		 float: right;
		 margin-top: 18px;
		 text-align: center;
	 }
   .foot_right img{
     width: 100px;
   }
	 .foot_right p{
		 	font-size: 16px;
		 	color: #b4b4b6;
			margin-top:15px;
	 }
	 .clear{
		 clear: both;
	 }
	 .record{
		 width: 100%;
		 text-align: center;
		 margin-top: 32px;
		 	color: #b4b4b6;
	 }
   .Business_license {
     width:80% ;
   }
   .el-dialog__footer{
     text-align: center!important;
   }
   .institution{
     height: 100%;
     margin-top: 0!important;
     overflow-y: scroll;
   }
   .institution span{
     line-height: 30px;
   }
   /*.el-dialog{*/
   /*  margin-left: 0!important;*/
   /*  margin-right: 0!important;*/
   /*}*/


   @media screen and (max-width: 768px) {
     .food {
       width: 96%;
       margin: 60px auto 0;
       padding-bottom: 40px;
       position: relative;
       border-bottom: 1px solid #999999;
     }
     .food_left {
       width: 100%;
       float: none;
     }
     .middle {
       width: 100%;
       float: none;
     }
     .foot_right{
	   position: relative;
	   right: 15%;
     }
     .record{
       margin-bottom: 10px;
     }
   }
   .institution p{
     line-height: 30px;
     color: #000;
   }
   .institution p strong{
      margin: 10px 0;
     font-size: 18px;
   }
   .institution h2 strong{
      margin: 30px 0;
     color: #000;
     font-size: 28px;
   }
   .PublicationLicence{
     color: #000;
   }
   .PublicationLicence span{
     color: #000;
     font-size: 16px!important;
     line-height: 40px;
   }
   .PublicationLicence span strong{
     margin: 30px 0!important;
     color: #000;
     font-size: 28px!important;
   }
   .PublicationLicence h2 span  {
     margin: 30px 0;
     color: #000;
     font-size: 28px!important;
     font-weight: bold;
   }
</style>
